import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { CustomersFacade } from '@app/store/customers';
import { UserFacade } from '@app/store/user';
import { AccountFacade } from '@corelabs/angular-account';
import { MessageService } from '@corelabs/angular-messages';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, filter, first, switchMap, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CleanupService {
    private readonly accountFacade = inject(AccountFacade);
    private readonly userFacade = inject(UserFacade);
    private readonly customersFacade = inject(CustomersFacade);
    private readonly router = inject(Router);
    private readonly translateService = inject(TranslateService);
    private readonly messageService = inject(MessageService);

    private subscriptions$ = new Subscription();

    constructor() {
        this.subscriptions$.add(
            this.accountFacade.loggedOut$
                .pipe(
                    filter((loggedOut) => loggedOut !== null),
                    tap(() => {
                        this.clear();
                        this.router.navigateByUrl('/auth/sign-in');
                    }),
                )
                .subscribe(),
        );

        this.subscriptions$.add(
            this.accountFacade.sessionExpired$
                .pipe(
                    switchMap(() =>
                        this.userFacade.userNullable$.pipe(
                            first(),
                            filter((user) => user !== null),
                            tap(() => {
                                this.clear();
                                this.messageService.add(this.translateService.instant('session.expired'), 'danger');
                                this.router.navigateByUrl('/auth/sign-in');
                            }),
                        ),
                    ),
                )
                .subscribe(),
        );
    }

    private clear() {
        this.userFacade.clear();
        this.customersFacade.clear();
        this.accountFacade.clear();
    }
}
