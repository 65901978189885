<header>
    <div>
        <a class="block" routerLink="/admin">
            <img class="block min-w-[5rem] max-w-[5rem] sm:min-w-[6rem] sm:max-w-[6rem] w-21" [src]="logo" alt="Logo" />
        </a>
    </div>

    <div>
        <app-language-switch />
    </div>
</header>
