import { Routes, Route } from '@angular/router';
import { ShellPublicComponent } from '../components/shell-public/shell-public.component';

export class ShellPublic {
    static childRoutes(routes: Routes): Route {
        return {
            path: '',
            component: ShellPublicComponent,
            children: routes,
        };
    }
}
