<div
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
    class="bg-{{ message.severity }}-600 px-4 py-2 text-sm rounded-default flex items-center md:w-[32rem] w-full"
    [ngClass]="message.severity === 'info' ? 'text-gray-700 font-medium' : 'text-white'"
>
    <div class="mr-4 leading-tight">{{ text }}</div>
    <button (click)="onMessageCloseClick()" class="relative w-[1.5rem] h-[1.5rem] p-[0.125rem] ml-auto">
        <app-icon name="mdi:close" class="icon-5 absolute left-0.5 top-0.5" [ngClass]="message.severity === 'info' ? 'icon-gray-700' : 'icon-white'"></app-icon>
        <svg class="absolute top-0 left-0" [attr.height]="24" [attr.width]="24">
            <circle
                [attr.stroke-width]="1.5"
                [attr.transform]="'rotate(-90, 12, 12)'"
                [attr.fill]="'transparent'"
                [attr.r]="10"
                [attr.cx]="12"
                [attr.cy]="12"
                [attr.stroke-dasharray]="62.8"
                [style.strokeDashoffset]="progress$ | async"
                [ngClass]="message.severity === 'info' ? 'stroke-gray-700' : 'stroke-white'"
            />
        </svg>
    </button>
</div>
