<div class="min-h-screen grid">
    <section class="w-full overflow-auto h-full flex justify-center items-center">
        <div [style.background-image]="'url(' + bgImage + ')'" class="p-6 w-[100vw] h-[100vh] bg-center bg-cover">
            <div class="box flex flex-col overflow-auto">
                <app-header />

                <div class="mt-10">
                    <router-outlet />
                </div>
            </div>
        </div>
    </section>
</div>
