import { Component } from '@angular/core';
import { options } from '@options';

@Component({
    selector: 'app-shell-public',
    templateUrl: './shell-public.component.html',
    styleUrls: ['./shell-public.component.scss'],
})
export class ShellPublicComponent {
    readonly bgImage = `${options.imagesPath}${options.loginBackground}`;
}
