import { Routes, Route } from '@angular/router';
import { ShellUserComponent } from '../components/shell/shell.component';
import { userGuard } from '@app/shells/guards/user.guard';
import { markAsOutdated } from '@app/store/user';

export class ShellUser {
    static childRoutes(routes: Routes): Route {
        return {
            path: '',
            canActivate: [userGuard()],
            canDeactivate: [markAsOutdated()],
            component: ShellUserComponent,
            children: routes,
        };
    }
}
