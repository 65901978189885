import { inject, Injectable } from '@angular/core';
import { AccountFacade, AccountMessagesService } from '@corelabs/angular-account';
import { messages$ } from '@corelabs/angular-core';
import { MessageService } from '@corelabs/angular-messages';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, Subscription, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MessagesBusService {
    private readonly messageService = inject(MessageService);
    private readonly accountMessagesService = inject(AccountMessagesService);
    private readonly accountFacade = inject(AccountFacade);
    private readonly translateService = inject(TranslateService);
    private readonly subscriptions$ = new Subscription();

    constructor() {
        this.subscriptions$.add(
            messages$
                .pipe(
                    map((message) => message.code),
                    filter((code) => code !== null),
                    tap((code) => {
                        const messageDictionary: Record<number, string> = {
                            400: 'request-error.bad-request',
                            403: 'request-error.forbidden',
                            404: 'request-error.not-found',
                        };

                        const message = this.translateService.instant(messageDictionary[code] ?? 'request-error.bad-request');
                        const displayedMessage = this.messageService.messages$.value.find((e) => e.message === message && e.severity === 'danger');

                        if (!displayedMessage) {
                            this.messageService.add(message, 'danger');
                        }
                    }),
                )
                .subscribe(),
        );

        this.subscriptions$.add(
            this.accountMessagesService.messages$
                .pipe(
                    tap((data) => {
                        const message = this.translateService.instant(`cl.account.${data.key}`);
                        this.messageService.clear();
                        this.messageService.add(message, data.type, data.code, data.key);
                    }),
                )
                .subscribe(),
        );

        this.subscriptions$.add(
            this.accountFacade.error$
                .pipe(
                    tap((error) => {
                        let message = '';
                        if (error.message === 'Invalid credentials.') {
                            message = 'cl.account.login-invalid-credentials';
                        } else {
                            message = `cl.account.${error.message}`;
                        }

                        this.messageService.add(this.translateService.instant(message), 'danger');
                    }),
                )
                .subscribe(),
        );
    }
}
